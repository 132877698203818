
.navbar-light {
  color: $primary;
}


#primary-nav {
  background: $primary;
  color: black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-transform: uppercase;
  font-family: $title-stack;
  letter-spacing: 1px;
  padding: 0;
  box-shadow: $shadow-down;

  button.navbar-toggler {
    margin: 10px 0;
    border-color: rgba(255, 255, 255, 0.9);
    border-width: 2px;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &[aria-expanded=true] .navbar-toggler-icon {
      transform: rotate(90deg);
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }

  .navbar-collapse {
    background-color: white;
    font-size: 1.15rem;

    .navbar-nav {
      padding-top: 4px;

      .current {
        padding: 8px 10px;
      }

      a.nav-link {
        color: $primary;
        padding: 8px 10px;
      } 

      a.nav-link.active {
        color: #444;
      }
    }
  }

  .dropdown-menu {
    border: 0;
    border-radius: 0;
    border-left: 1px solid #ccc;
    font-size: 1.15rem;

    .dropdown-item {
      color: $primary;
      background-color: transparent;
    }
  }
}

@media (min-width: 992px) {
  #primary-nav {
    #navbar-toggler-container {
      display: none;
    }

    .navbar-collapse {
      font-size: 1.25rem;
      background-color: transparent;

      .navbar-nav {
        
        a.nav-link {
          color: white;
        }

        .nav-item.nav-spacer {
          margin-left: auto;
        }

        .nav-item:first-of-type *
        {
          padding-left: 0;
        }
      }
    }

    .dropdown-menu {
      margin-top: 0px;
      padding-top: 18px;
      border: 1px solid #000;
      background: $gray-100;
      color: $primary;
      box-shadow: $shadow-down;

      .dropdown-item {
        background: $gray-100;
        color: $primary;
      }
    }
  }
}
