@import "bootstrap/bootstrap-utilities";

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "gray":       $gray-600,
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px
);

$baseurl: "https://www.ogpc.info/";

@import "custom-variables";
@import "bootstrap/bootstrap";
@import "custom-styles";
