@import '_ogpc-general.scss';

@import '_ogpc-nav.scss';

body {
  position: relative;
  min-height: 100vh;
  padding-bottom: $footer-height; 
}

article.season {
    img.theme-logo {
        max-width:100%;
        max-height:300px;
        width: auto;
        height: auto;
        margin-bottom: 1.5em;
    }
} 

h1.page-heading {
    margin-top: 1.2em;
}

.site-branding img {
  width: 100%;
  max-width: 400px;
}



.container > * >  p > img {
  margin: 1rem auto;
  display: block;
  max-width: 100%;
  border: 1px solid $gray-800;
}

main a {
  font-weight: bold;
}

.btn {
  box-sizing: content-box;
  display: block;
  max-width: fit-content;
  border-radius: 0px;
  padding: 10px 30px;
  background: $primary;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  box-shadow: $shadow-down;
  margin: 0 auto;

  &:hover {
    color: white;
    border: 1px solid black;
  }
}

.btn-dark {
  background: $gray-900;
  &:hover {
    color: white;
    border: 1px solid $primary;
  }
}

.btn-set {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 30px;

  p {
    flex: none;
    margin: 0;
    a {
      white-space: nowrap;
    }
  }
}

.yt-embed {
  max-width: 100%;
  margin: 2rem auto;
  display: block;
  border: 1px solid $gray-800;
}

.imagegroup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem 30px;

  p {
    flex: 1;
    img {
      border: 1px solid $gray-800;
    }
  }
}

.home > h1 + h2 {
  margin-top: 1.2em;
}

.sponsor-block {
  text-align: center;

  h3 {
    font-size: 1.25rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem 30px;
    padding: 0;
    padding-top: 1rem;
  
    li {
      list-style: none;
      flex: none;
      
      img {
        max-width: 300px;
        max-height: 100px;
      }

      img.sustaining {
        @media (min-width: 768px) {
          max-width: 400px;
          max-height: 150px;
        }
      }

      img.thanks {
        max-width: 200px;
        max-height: 80px;
      }
    }
  }
}

footer {
  padding-top: 25px;
  padding-bottom: 10px;
  border-top: 1px solid #444;
  box-shadow: $shadow-up;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height; 
}
